import React from 'react';
import styled, { css } from 'styled-components';

import { Box } from '@core';
import { LinkWrapper, Text } from '@components';

import { ArrowIcon } from './arrow-icon';

const ContentWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.primary};

  svg {
    path:nth-child(1) {
      fill: ${({ theme }) => theme.colors.primary_80};
    }

    path:nth-child(2) {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary_80};

    svg {
      path:nth-child(1) {
        fill: ${({ theme }) => theme.colors.primary_60};
      }

      path:nth-child(2) {
        fill: ${({ theme }) => theme.colors.primary_80};
      }
    }
  }

  &:active {
    color: ${({ theme }) => theme.colors.primary_120};

    svg {
      path:nth-child(1) {
        fill: ${({ theme }) => theme.colors.primary};
      }

      path:nth-child(2) {
        fill: ${({ theme }) => theme.colors.primary_120};
      }
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.darkBlue_40};

      svg {
        path:nth-child(1) {
          fill: ${({ theme }) => theme.colors.darkBlue_20};
        }

        path:nth-child(2) {
          fill: ${({ theme }) => theme.colors.darkBlue_40};
        }
      }
    `}
`;

const ArrowLink = ({ children, disabled, variant = 'forward', ...rest }) => (
  <LinkWrapper disabled={disabled} {...rest}>
    <ContentWrapper disabled={disabled}>
      {variant === 'backward' ? (
        <React.Fragment>
          <Box display="flex" alignItems="center">
            <ArrowIcon rotation={180} />
          </Box>
          {children && (
            <Text.Body2Strong ml={12} as="span" color="inherit">
              {children}
            </Text.Body2Strong>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {children && (
            <Text.Body2Strong mr={12} as="span" color="inherit">
              {children}
            </Text.Body2Strong>
          )}
          <Box display="flex" alignItems="center">
            <ArrowIcon />
          </Box>
        </React.Fragment>
      )}
    </ContentWrapper>
  </LinkWrapper>
);

export default ArrowLink;
